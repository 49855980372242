<template>
    <div>
      <section id="bio">
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-1">
            <v-flex xs12 sm3>
              <h3 v-text="$t('Views.Home.bio.title')"></h3>
            </v-flex>
            <v-flex xs12 sm3>
             <ul class="transparent column-list">
               <li v-for="item in $t('Views.Home.bio.first')" :key="item.id">
                 <modal v-if="item.desc" :item="item"/>
                 <span v-else>{{item.name}}</span>
               </li>
             </ul>
            </v-flex>
            <v-flex xs12 sm3>
             <ul class="transparent column-list">
               <li v-for="item in $t('Views.Home.bio.second')" :key="item.id">
                 <modal v-if="item.desc" :item="item"/>
                 <span v-else>{{item.name}}</span>
               </li>
            </ul>
            </v-flex>
            <v-flex xs12 sm3>
             <ul class="transparent column-list">
               <li v-for="item in $t('Views.Home.bio.third')" :key="item.id">
                 <modal v-if="item.desc" :item="item"/>
                 <span v-else>{{item.name}}</span>
               </li>
             </ul>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
     <section id="releases">
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-1">
            <v-flex xs12 sm3><h2>Select Releases</h2></v-flex>
            <v-flex xs12 sm9>
              <alpha-card-feature :features="$t('Views.Home.releases.items')"></alpha-card-feature>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
      <!--
      <section id="photos-">
        <v-parallax src="/static/pic-30.jpg" height="400">
        </v-parallax>
      </section>
      -->

     <section id="photos">
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-1">
            <v-flex xs12 sm3><h2>Photos</h2></v-flex>
            <v-flex xs12 sm9>
                <v-carousel hide-delimiters :height="imageHeight">
                  <v-carousel-item
                      v-for="(item,i) in $t('Views.Home.photos.items')"
                      :key="i"
                      :src="'/static/pics/' + item.src"
                  ></v-carousel-item>
                </v-carousel>
            </v-flex>
          </v-layout>
        </v-container>
      </section>
      <section id="video">
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-1">
            <v-flex xs12 sm3><h2>Video</h2></v-flex>
            <v-flex xs12 sm9>
              <v-container grid-list-xl pa-0>
                <v-layout row wrap justify-center >
                  <v-flex xs12 sm5 d-flex  v-for="vid in $t('Views.Home.video.items')" :key="vid.id" >
                    <v-card flat tile>
                        <iframe class="video-size" :src="vid.src" frameborder="0" allowfullscreen></iframe>
                        <v-card-title style="margin-top: -0.35rem; padding-top: 0">{{vid.title}}</v-card-title>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </v-container>
      </section>

     <section id="gear">
        <v-parallax src="/static/equip.jpg" height="300">
        </v-parallax>
      </section>

     <section >
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-1">
            <v-flex xs12 sm3><h2>Gear</h2></v-flex>
            <v-flex xs12 sm3>
              <h5>Drums: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.drums.logo')" class="gear-logo">
              <br><a :href="$t('Views.Home.gear.drums.link')" target="_blank">{{ $t('Views.Home.gear.drums.link') }}</a>
              <h4 v-text="$t('Views.Home.gear.drums.title')"></h4>
              <ul>
                <li v-for="item in $t('Views.Home.gear.drums.items')" :key="item.id">{{ item }}</li>
              </ul>
              <br/>
              <h5>Hardware: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.hardware.logo')" class="gear-logo" style="height: 70px">
              <h4 v-text="$t('Views.Home.gear.hardware.title')"></h4>
              <ul>
                <li v-for="item in $t('Views.Home.gear.hardware.items')" :key="item.id">{{ item }}</li>
              </ul>
            </v-flex>
            <v-flex xs12 sm3>
              <h5>Cymbals: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.cymbals.logo')" class="gear-logo" style="height: 60px">
              <br><a :href="$t('Views.Home.gear.cymbals.link')" target="_blank">{{ $t('Views.Home.gear.cymbals.link') }}</a>
              <h4 v-text="$t('Views.Home.gear.cymbals.title')"></h4>
              <ul>
                <li v-for="item in $t('Views.Home.gear.cymbals.items')" :key="item.id">{{ item }}</li>
              </ul>
              <h4 v-text="$t('Views.Home.gear.other.title')"></h4>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.other.logo')" class="gear-logo" style="height: 75px">
            </v-flex>
            <v-flex xs12 sm3>
             <h5>Sticks: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.sticks.logo')" class="gear-logo" style="height: 65px">
              <br><a :href="$t('Views.Home.gear.sticks.link')" target="_blank">{{ $t('Views.Home.gear.sticks.link') }}</a>
              <h4 v-text="$t('Views.Home.gear.sticks.title')"></h4>
              <ul>
                <li v-for="item in $t('Views.Home.gear.sticks.items')" :key="item.id">{{ item }}</li>
              </ul>
              <h5>Heads: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.heads.logo')" class="gear-logo" style="height: 65px">
              <h4 v-text="$t('Views.Home.gear.heads.title')"></h4>
              <ul>
                <li v-for="item in $t('Views.Home.gear.heads.items')" :key="item.id">{{ item }}</li>
              </ul>
              <h4 v-text="$t('Views.Home.gear.other.title2')"></h4>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.other.logo2')" class="gear-logo" style="height: 75px; background-color: #000">
            </v-flex>
            <v-flex xs12 sm3>
            </v-flex>
            <v-flex xs12 sm3>
              <h5>Percussion: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.percussion.logo')" class="gear-logo">
              <h4 v-text="$t('Views.Home.gear.percussion.title')"></h4>
              <ul>
                <li v-for="item in $t('Views.Home.gear.percussion.items')" :key="item.id">{{ item }}</li>
              </ul>
              <br />
              <h5>Cases: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.cases.logo')" class="gear-logo" style="height: 65px">
            </v-flex>
            <v-flex xs12 sm3>
              <h5>Electronics: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.electronics.logo')" class="gear-logo" style="height: 65px">
              <h4 v-text="$t('Views.Home.gear.electronics.title')"></h4>
              <ul>
                <li v-for="item in $t('Views.Home.gear.electronics.items')" :key="item.id">{{ item }}</li>
              </ul>
              <br />
              <h5>Software: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.software.logo')" class="gear-logo" style="height: 65px">
            </v-flex>

            <v-flex xs12 sm3>
              <h5>Microphones: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.microphones.logo')" class="gear-logo" style="height: 60px">
              <br><img :src="'/static/gear-logos/' + $t('Views.Home.gear.microphones.logo2')" class="gear-logo" style="height: 70px">
              <h5>Pro Audio: </h5>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.pro-audio.logo')" class="gear-logo" style="height: 32px">
              <h4 v-text="$t('Views.Home.gear.pro-audio.title')"></h4>
              <ul>
                <li v-for="item in $t('Views.Home.gear.pro-audio.items')" :key="item.id">{{ item }}</li>
              </ul>
              <br/>
              <h5>Monitoring: </h5>
              <h4 v-text="$t('Views.Home.gear.monitoring.title')"></h4>
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.monitoring.logo')" class="gear-logo" style="height: 65px">
              <img :src="'/static/gear-logos/' + $t('Views.Home.gear.monitoring.logo2')" class="gear-logo" style="height: 50px">
              <ul>
                <li v-for="item in $t('Views.Home.gear.monitoring.items')" :key="item.id">{{ item }}</li>
              </ul>
            </v-flex>

          </v-layout>
        </v-container>
      </section>

     <section id="studio">
        <v-parallax src="/static/studio.jpg" height="500">
        </v-parallax>
     </section>

     <section>
         <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-1">
            <v-flex xs12 sm3>
              <h2>Live Wire Studio</h2>
            </v-flex>
            <v-flex xs12 sm9>
              <p>Live Wire Studio is 30 minutes from New York City in West Orange, NJ and was built to adapt to the ever changing music business and budgets. We are here to make a difference and give our heart and soul to your music.</p>
              <h3>What you can expect</h3>
              <p>Whether working here with us or remotely you will have full access to my drum collection including all of my Gretsch USA Custom, Brooklyn, Broadkaster kits including 18”, 20”, 22”, 24” kicks and 8”, 10”, 12”, 14”, 16”, 18” toms, 100 plus Sabian Cymbals, 50 plus snare drums,  every model of Evans Drum Head, my large microphone collection including multiples of every Beyer Dynamic mic all running through Neve, API 3124 and Chameleon LAB 7622  mic pres, RME UFX and Digidesign 192/96 Interfaces. We track in Pro Tools, 12, 10, Logic Pro X and program in Ableton Live depending on the project and are adapt at importing/exporting audio from all digital audio programs.  We have a gorgeous warm sounding drum room tweaked and treating with a mixture of wood, custom sound panels by Studio VU and Auralex products that opens up into another tracking room treating similarly. Upstairs we have separate rooms for guitar/bass amps with complete separation.</p>

              <h3>How it works</h3>
    <p>Hiring me for online drum tracks would involve you sending an mp3 of the song/songs including whatever ideas you have for the drum parts and production.  We collaborate together via video chat/phone to talk music and negotiate a price together that works for you. After you Payal or Venmo ½ of the rate I’d then send you a rough mp3 to make sure we are on target and proceed accordingly until we are locked in on the performance.  After settling up with the payment balance I would upload 2 or 3 takes of the multi track sessions which would include either the Pro Tools session or Wave files. If we track with you here at the studio we skip the mp3 process and get straight to multi tracking together. The drum tracks whether uploaded or put on your external hard drive would be the following:</p>

                <ul>
                    <li>Kick in</li>
                    <li>Kick out</li>
                    <li>Snare top</li>
                    <li>Snare bottom</li>
                    <li>Snare 2 (optional)</li>
                    <li>Snare Crotch  (a mic over the bass drum)</li>
                    <li>Hi Hat(s)</li>
                    <li>All Tom tracks (separate)</li>
                    <li>Over Head L/R</li>
                    <li>Room mid L/R </li>
                    <li>Room Far  L/R</li>
                    <li>Percussion Tracks(if needed) Tambourine/Shakers/Maracas/Bongos/Djembe/Cajon/Congas</li>
                    <li>Electronic Drum Sounds, Loops</li>
                    <li>A mixed, edited stereo drum track to use with your session when negotiated.</li>
                    <li>Other bass, guitar, keys, instrumental, vocal tracks of my session buddies when negotiated.</li>
                </ul>
            </v-flex>
          </v-layout>
         </v-container>
      </section>

      <section id="contact">
        <v-parallax src="/static/kit.jpg" height="300">
        </v-parallax>
      </section>

      <section>
      <v-container grid-list-xl>
        <v-layout row wrap justify-center class="my-1">
          <v-flex xs12 sm3><h2>Contact</h2></v-flex>
          <v-flex xs12 sm9>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                  v-model="name"
                  label="Name"
                  required
                  ref="name"
              ></v-text-field>
              <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  ref="email"
              ></v-text-field>
              <v-textarea
                  v-model="message"
                  :rules="messageRules"
                  label="Message"
                  ref="message"
              ></v-textarea>

              <v-btn
                  :disabled="!valid"
                  @click="submit"
              >
                submit
              </v-btn>
              <v-btn @click="clear">clear</v-btn>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
      </section>
    </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data: () => ({
      valid: true,
      name: '',
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      messageRules: [
        v => !!v || 'Please enter a message',
      ],
      message: ''
    }),

    computed: {
      imageHeight () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '300px'
          case 'sm': return '400px'
          case 'md': return '500px'
          case 'lg': return '600px'
          case 'xl': return '600px'
        }
      }
    },

    methods: {
      submit () {
        if (this.$refs.form.validate()) {
          axios.post('/bin/contact.cgi', {
            name: this.name,
            email: this.email,
            message: this.message
          }).then(() => {
             this.clear();
             this.$refs.message.success=true
             this.$refs.message.successMessages="Thanks!  I got it."
          }).catch(() => {
            //console.log(error);
          });
        }
      },
      clear () {
        this.$refs.message.success=false
        this.$refs.message.successMessages=""
        this.$refs.form.reset()
      }
    }
  }
</script>
<style>
  .column-list {
    list-style: none;
  }
  .video-size {
    width: 100%;
    height: 75%;
  }
  .gear-logo {
    height: 95px;
    padding: 0 5px 0 0;
  }

  /*
  .v-image__image--cover {

    background-size: contain;

  }
  */

</style>
